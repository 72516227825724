import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_DELIVERY_FEE, GET_SALES_TAX, GET_CONVENIENCE_FEE } from "../../../../Graphql/queries";
import { useEffect, useMemo, useState } from "react";
import { getPercentValue } from "@utils/order-format.utils";
import { foodAndBeverageTotal } from "../../../../Modules/Common/commonUtils";
import { authVar } from "../../../../ReactiveVariables";
import { useNavigate } from "react-router";
import { validateCartError, validateCartWarnings } from "./cart-view.utils";
import { ORDER_STATUS, ORDER_TIP_TYPE } from "@constants/order.constants";
import { useOrderCalculation } from "@utils/hooks/use-order-calculation";
import { IOrderPayload } from "@services/redux/order";
import { ROUTES } from "@constants/routes.constants";

interface ICartViewStateProps {
  caterer: {
    zip: string;
    city: string;
    state: string;
    id: string;
    minOrderAmount: number;
    isTipMandatory: boolean;
    isMandatoryTipPercentage: boolean;
    mandatoryTipValue: number;
  };
  customerId: string;
  order: IOrderPayload;
  tableware: {
    isCharged: boolean;
    cost: number;
  };
  headCount: number;
  isAvailable: "date" | "address" | true;
  onUpdateOrder(params: Partial<IOrderPayload>): void;
  onUpdateTotalCost(total: number): void;
}

export function useCartViewState({
  caterer,
  order,
  customerId,
  tableware,
  headCount,
  isAvailable,
  onUpdateOrder,
  onUpdateTotalCost,
}: ICartViewStateProps) {
  const auth = useReactiveVar(authVar);
  const navigate = useNavigate();

  const [cartError, setCartError] = useState(null);
  const [cartWarnings, setCartWarnings] = useState([]);

  const cartCost = useMemo(() => {
    return foodAndBeverageTotal(order.cartDetails);
  }, [order.cartDetails, order.cartDetails.length]);

  const cartItemsQuantity = useMemo(() => {
    return order.cartDetails.reduce((sum, item) => sum + item.quantity, 0);
  }, [order.cartDetails, order.cartDetails.length]);

  const mandatoryTip = useMemo(() => {
    return caterer.isTipMandatory
      ? caterer.isMandatoryTipPercentage
        ? getPercentValue(cartCost * caterer.mandatoryTipValue)
        : caterer.mandatoryTipValue
      : 0;
  }, [
    caterer.isTipMandatory,
    cartCost,
    caterer.isMandatoryTipPercentage,
    caterer.mandatoryTipValue,
  ]);

  const { data: salesTaxData } = useQuery(GET_SALES_TAX, {
    variables: {
      zip: caterer.zip,
      city: caterer.city,
      catererStateShort: caterer.state,
      customerId: customerId,
    },
  });

  const { data: deliveryFeeData } = useQuery(GET_DELIVERY_FEE, {
    variables: {
      catererId: caterer.id,
      totalCost: cartCost,
      orderType: order.deliveryMode,
      zipCode: order.zip,
    },
    onCompleted(data) {
      onUpdateOrder({
        deliveryFee: data.getDeliveryFee.deliveryFee,
      });
    },
  });

  const { data: convenienceFeeData } = useQuery(GET_CONVENIENCE_FEE, {
    variables: {
      totalCost: cartCost + tableware.cost,
    },
    onCompleted(data) {
      onUpdateOrder({
        convenienceFee: data.getConvenienceFee.convenienceFee,
      });
    },
  });

  const tablewareCost = useMemo(() => {
    if (order.tablewareDeclined || !tableware.isCharged) {
      return 0;
    }
    return headCount * tableware.cost;
  }, [tableware, order.tablewareDeclined]);

  const { taxCost, totalCost } = useOrderCalculation({
    cartCost,
    tableware: tablewareCost,
    salesTaxPercent: salesTaxData ? salesTaxData.getSalesTax.salesTax : 0,
    deliveryFee: deliveryFeeData ? deliveryFeeData.getDeliveryFee.deliveryFee : 0,
    driverTip: caterer.isTipMandatory ? mandatoryTip : order.driverTip,
    convenienceFee: convenienceFeeData ? convenienceFeeData.getConvenienceFee.feeTotal : 0,
    discount: 0,
  });

  useEffect(() => {
    onUpdateTotalCost(totalCost);
  }, [totalCost]);

  useEffect(() => {
    if (!order.tipPercentage) {
      return;
    }

    onUpdateOrder({
      driverTip: getPercentValue(cartCost * order.tipPercentage),
    });
  }, [cartCost]);

  function onChangePercentageTip(value: number | null) {
    const newTip = value ? getPercentValue(cartCost * value) : 0;

    onUpdateOrder({
      driverTip: newTip,
      tipPercentage: value,
      driverTipType: ORDER_TIP_TYPE.percentage,
    });
  }

  function onChangeCustomTip(value: number) {
    const newTip = value || 0;

    onUpdateOrder({
      driverTip: newTip,
      tipPercentage: 0,
      driverTipType: ORDER_TIP_TYPE.custom,
    });
  }

  function onAddTableware() {
    onUpdateOrder({
      tablewareTotal: headCount * tableware.cost,
      tablewareDeclined: false,
    });
  }

  function onRemoveTableware() {
    onUpdateOrder({
      tablewareTotal: 0,
      tablewareDeclined: true,
    });
  }

  async function onDeleteCartItem(id, index) {
    const newCartData = [...order.cartDetails];
    newCartData.splice(index, 1);
    onUpdateOrder({
      cartDetails: newCartData,
    });
  }

  function onCloseCartError() {
    setCartError(null);
  }

  function onCloseCartWarnings() {
    setCartWarnings([]);
  }

  function onNextWarning() {
    const newWarnings = cartWarnings.length ? cartWarnings.slice(1) : cartWarnings;
    setCartWarnings(newWarnings);
    if (!newWarnings.length) {
      onNextStep();
    }
  }

  function onSubmit() {
    const error = validateCartError({
      address: order.street,
      zip: order.zip,
      headCount,
      isAvailable: isAvailable,
      cartCost: cartCost,
      minOrderAmount: caterer.minOrderAmount,
      date: order.deliveryDate ? new Date(order.deliveryDate) : null,
    });

    if (error) {
      return setCartError(error);
    }

    const warnings = validateCartWarnings({
      headCount,
      cartItemsQuantity: cartItemsQuantity,
      isTableWareCharged: tableware.isCharged,
      tip: order.driverTip,
      deliveryMode: order.deliveryMode,
      isTipMandatory: caterer.isTipMandatory,
    });

    if (warnings.length) {
      return setCartWarnings(warnings);
    }

    onNextStep();
  }

  function onNextStep() {
    if (auth.auth) {
      navigate(
        `${ROUTES.checkout}/${
          !order.orderStatus || order.orderStatus === ORDER_STATUS.draft || !order._id
            ? "new"
            : order._id
        }`,
      );
    } else if (!auth.auth) {
      navigate(ROUTES.customerLogin);
    }
  }

  return {
    alertModal: {
      onClose: cartError ? onCloseCartError : onCloseCartWarnings,
      onContinue: onNextWarning,
      type: cartError ? "error" : cartWarnings.length ? "warning" : "hidden",
      title: cartError ? cartError.title : cartWarnings.length ? cartWarnings[0].title : "",
      description: cartError
        ? cartError.description
        : cartWarnings.length
        ? cartWarnings[0].description
        : "",
    },
    cart: {
      cartItemsQuantity,
      onDeleteCartItem,
    },
    tip: {
      value: order.driverTip,
      percentageTip: order.tipPercentage,
      mandatoryTip,
      onChangeCustomTip,
      onChangePercentageTip,
    },
    salesTax: {
      percentage: salesTaxData ? salesTaxData.getSalesTax.salesTax : 0,
      value: taxCost,
    },
    tableware: {
      value: tablewareCost,
      isTablewareRemoved: order.tablewareDeclined,
      onAddTableware,
      onRemoveTableware,
    },
    cost: {
      totalCost,
      cartCost,
      deliveryFee: deliveryFeeData ? deliveryFeeData.getDeliveryFee.deliveryFee : 0,
      convenienceFee: convenienceFeeData ? convenienceFeeData.getConvenienceFee.feeTotal : 0,
    },
    onSubmit,
  };
}
