import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { formatter } from "../../Modules/Common/commonUtils";

interface IConvenienceFeeProps {
  total: number;
}

export function ConvenienceFee({ total }: IConvenienceFeeProps) {
  return (
    <div className="other-charges-item">
      <label>
        Service Fee
      </label>
      <b>
        <Tooltip title="This 5% Service Fee (minimum $25) helps us operate CaterPlace." placement="top">
          <IconButton sx={{ padding: 0, marginRight: "5px", bottom: "1px" }}>
            <img src="../assets/images/icon-info.svg" className="img-fluid" alt="Delete Item" />
          </IconButton>
        </Tooltip>
        {formatter.format(total)}
      </b>
    </div>
  );
}
